import { _axios } from '@/utils/axios'

export function getHomeData(params) {
  return _axios({
    url: '/accelerator/home/data',
    method: 'get',
    params
  })
}


export function getAcceleratorInfo(params) {
  return _axios({
    url: '/accelerator/info',
    method: 'get',
    params
  })
}

export function getNotify(params) {
  return _axios({
    url: '/accelerator/notify',
    method: 'get',
    params
  })
}

